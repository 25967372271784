import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

import "../styles/index.scss"

const BlogPost = ({ data, pageContext }) => {
  const { title, content, tags, image } = data.contentfulBlogPost
  const { previous, next } = pageContext
  const body = content.childMarkdownRemark.html
  return (
    <Layout>
      <SEO title={title} description={tags} />
      <div className="surroundBlog">
        <div className="blogContent">
          <div>
            <Img alt={title} fluid={image.fluid} />
          </div>
          <div className="wrapper">
            <h1 className="blogTitle">{title}</h1>
            <div dangerouslySetInnerHTML={{__html: body}} />
            <div className="tags">
              {tags.map(tag => (
                <span className="tag" key={tag}>
                  {tag}
                </span>
              ))}
            </div>
            <div className="blogPagination">
              <div className="prevButton">
              {previous && (
                <Link to={`/blogpost/${previous.slug}/`} className="paginationButton">
                  Previous
                </Link>
              )}
              </div>
              <div className="nextButton">
              {next && (
                <Link to={`/blogpost/${next.slug}/`} className="paginationButton">
                  Next
                </Link>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      image {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      content {
        childMarkdownRemark {
          html
        }
    }
      tags
      author {
        authorName
      }
    }
  }
`
